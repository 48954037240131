import React from "react";
import SkeletonElement from "./SkeletonElement";
import styled from "styled-components";
import Shimmer from "./Shimmer";

const Wrapper = styled.div`
  padding: 10px;
`;

const Container = styled.div``;

const SkeletonProduct = ({ theme }) => {
  const themeClass = theme || "light"

  return (
    <Wrapper className={`skeleton-wrapper ${themeClass}`}>
      <Container>
        <SkeletonElement type="thumbnail" />
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="body" />
      </Container>
      <Shimmer />
    </Wrapper>
  );
};

export default SkeletonProduct;
